/* eslint-disable */
import { readCookie } from '../../utils/cookie_manager';
import { currentDevice } from '../../core/utils/currentDevice';
import on from '../../utils/events_delegation';
import '../hotels_map_for_filter_page/hotels_map_for_filter_page';
import _filter from 'lodash/filter';
import _uniq from 'lodash/uniq';
import _omit from 'lodash/omit';
import _omitBy from 'lodash/omitBy';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _pickBy from 'lodash/pickBy';
import _size from 'lodash/size';
import _forEach from 'lodash/forEach';
import _difference from 'lodash/difference';
import _values from 'lodash/values';

import { initializeUtagEvents } from './filters_sidebar.analytics';

const LOCALSTORAGE_KEY = 'hotel_search';
const AUTO_OFFER_LIST_PRESENT = document.querySelectorAll ('.automatic-offer-list');
const filters = { destinations: [], categories: [], specialties: [], amenities: [], budgets: [], favourite: [] };
let currentHotels = [];

// Get LocalStorage key for search criteria
function getLocalStorage() {
  let search;
  try {
    search = localStorage.getItem(LOCALSTORAGE_KEY);
  } catch (e) { }
  search = initializeOrFindSearch(search);
  return search;
}

// Save search criteria to LocalStorage
function setLocalStorage(search) {
  try {
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(search));
  } catch (e) { }
}

// Empty LocalStorage search criteria
function emptyLocalStorage() {
  localStorage.removeItem(LOCALSTORAGE_KEY);
}


function applyFilters($wrapper, $element) {
  let $elements = $wrapper.find('[data-filterable]');
  if (!$elements.length) return;

  let $filters_sidebar = $('.js-filters-sidebar');
  let filters = { destinations: [], categories: [], specialties: [], amenities: [], budgets: [], favourite: [] };
  let available_options = { categories: [], specialties: [], amenities: [], favourite: [] };
  let global_counter = 0;
  let $total_counter = $('.total-hotels-counter');
  let $total_counter_mice = $('.meetings .destination-index-header .counter-search-meetings');
  currentHotels = [];

  $filters_sidebar.find('[data-filter]:checked').map(function () {
    let filter_value = $(this).val();
    if ($(this).attr('data-filter') !== 'categories') {
      filter_value = parseInt($(this).val(), 10);
    }
    filters[$(this).attr('data-filter')]?.push(filter_value);
  });

  if ((!currentDevice.isMobile|| !currentDevice.isLikeMobile) && $element === undefined) createAllCheckedTags($filters_sidebar);

  $('.destination-set').removeClass('hidden');

  checkFiltersBottomActionsVisibility();

  let $filtered_els = $elements.removeClass('hidden').filter(function () {
    let $this = $(this);
    let data = $this.data('filterable');

    if (!_filter(filters, function (o) {return o.length;}).length) {
      let index;
      for (index = 0; index < data.length; index++) {
        $.each(available_options, function (key) {
          available_options[key] = _uniq(available_options[key].concat(data[index][key]));
        });
      }
      if ($this.hasClass('dest-card')) combineText($this.find('.hotel-count'), data.length, getVisibleHotels().length);

      return true;
    }

    let f_destinations = filters.destinations;
    let f_other_obj = _omit(filters, ['destinations']);
    let filters_omit = _omitBy(f_other_obj, _isEmpty);
    let valid_destination = false;
    let valid_options = false;
    let valid = false;
    let counter = 0;
    let visibleHotels = getVisibleHotels();
    let omit;

    let index;
    for (index = 0; index < data.length; index++) {
      let obj = {};

      valid_destination = f_destinations.length ? data[index].destinations.some(function (dest) {return _includes(f_destinations, dest);}) : true;

      omit = _pickBy(data[index], function (value, key) {
        return filters_omit[key] !== undefined;
      });

      if (_size(omit)) {
        _forEach(omit, function (value, key) {
          obj[key] = _difference(filters_omit[key], data[index][key]).length === 0;
        });

        valid_options = !_includes(_values(obj), false);
      } else {
        valid_options = true;
      }

      if (valid_options && valid_destination) {
        counter++;
        $.each(available_options, function (key) {
          if (data[index][key] !== undefined) available_options[key] = _uniq(available_options[key].concat(data[index][key]));
        });

        if ($this.hasClass('dest-card')) combineText($this.find('.hotel-count'), counter, visibleHotels.length);
      }

      if (!valid) valid = valid_options && valid_destination;
    }

    return valid;
  });

  $elements.not($filtered_els).addClass('hidden');

  global_counter = getVisibleHotels().length;
  // Actualizamos los data-global-counter si los hay
  if ($('[data-global-counter]').length) {
    $('[data-global-counter]').find('b').text(global_counter);
  }

  // Esconder los paises que no tienen destinos válidos para los filtros
  $('.destination-set').each(function (i, element) {
    let $actual = $(element);
    $actual.toggleClass('hidden', $actual.find('.js-dest-card:not(.hidden)').length === 0);
  });

  // Deshabilitamos todos los checks que no tengan posibles resultados
  disableFiltersWithoutVisibleElements($filters_sidebar, available_options);
  $wrapper.trigger('filtering.finish', filters);
  if ((!currentDevice.isMobile|| !currentDevice.isLikeMobile)) {
    if ($('#hotels-filter-map').is(':visible')) {
      IB.hotels_filter_map.filterVisible();
    }
  }
  if (_filter(filters, function (o) {return o.length;}).length) {
    let visibleHotels = getVisibleHotels().length;
    $total_counter.find('.counter').text(visibleHotels);
    $total_counter.removeClass('hidden');

    visibleHotels >= 1 ? $('.message-hotels-not-found').addClass('hidden') : $('.message-hotels-not-found').removeClass('hidden');

    // Header results mice searches
    if ($total_counter_mice.length > 0) {
      $total_counter_mice.find('b').html(visibleHotels);
      let spanText = $total_counter_mice.parent().find('.results-search-meetings');
      if (visibleHotels > 1) {
        spanText.html(spanText.data('text-plural'));
      } else {
        spanText.html(spanText.data('text-singular'));
      }
    }else{
      let visibleHotels = getVisibleHotelsInHotelPage(filters);
      currentHotels = visibleHotels;
      $total_counter.find('.counter').text(visibleHotels.length);

      //filtros
      disableFiltersWithoutVisibleElements($filters_sidebar, available_options,visibleHotels);
      FilterSidebar.updateFiltersCounter();
    }
  } else {
    $total_counter.addClass('hidden');
  }

  if ($element !== undefined) {
    let $select_all = $element.closest('.filters').find('.select-all').find('input');

    if ($element.attr('data-filter') === 'destinations' && $select_all.length && $select_all.hasClass('selected')) {
      $select_all.prop('checked', false).change();
      $select_all.removeClass('selected');
    }
  }
}

function getVisibleHotelsInHotelPage(filters) {
  let total_filters = _filter(filters, function (o) { return o.length; }).length;
  let visibleHotels = [];
  let hotels = document.querySelectorAll('.destination-list:not(.hidden) .dest-card:not(.hidden)');
  hotels.forEach(function (element) {
    const options = JSON.parse(element.dataset.filterable);
    const hotels = JSON.parse(element.dataset.hotels);
    options.forEach(function (option, index) {
      const options_name = Object.keys(option);
      let valid_options = 0;
      options_name.forEach(function (key) {
        if(!filters[key].length){
          return false
        }

        if (key !== 'destinations' && filters[key].every(r => option[key].includes(r))) {
          valid_options++;
        }

        if (key === 'destinations' && filters[key].some(r => option[key].includes(r))) {
          valid_options++;
        }
      });
      if (valid_options == total_filters) {
        visibleHotels.push(hotels[index]);
      }
    });
  });
  return visibleHotels;
}

function contains(values, array) {
  return array.some(function (v) {
    return values.indexOf(v) >= 0;
  });
}

function clearFilters($wrapper, kinds) {
  kinds = (typeof kinds === 'undefined') ? ['destinations', 'categories', 'specialties', 'amenities', 'favourite'] : kinds;
  $.each(kinds, function () {
    $('.js-filters-sidebar').find('[data-filter="' + this + '"]:checked, .select-all input:checked').prop('checked', false)
      .change();
  });
  applyFilters($wrapper);
  FilterSidebar.updateFiltersCounter();
}

function selectorForFilter(key, values) {
  let selector = [];
  for (let i = 0; i < values.length; i++) {
    selector.push('[value="' + values[i] + '"]');
  }
  return selector.join(',');
}

function disableFiltersWithoutVisibleElements($wrapper, available_options) {
  let $checkboxes = $wrapper.find('[data-filter]').not('[data-filter="destinations"]');

  if (!_filter(available_options, function (o) {return o.length;}).length) {
    return true;
  }
  // Active all
  $checkboxes.prop('disabled', false).trigger('formikation.update');

  // Disable filters that doesn't have hotels in visible cards
  let visibleHotels = getVisibleHotels();
  if(currentHotels.length){
    visibleHotels = currentHotels;
  }

  $checkboxes.filter(function () {
    let $this = $(this);
    let visibleHotelNumberInFilter = 0;
    visibleHotels.forEach((hotel) => {
      if (_includes(Object.values($this.data('hotels-by-zone')).flat(), hotel)) visibleHotelNumberInFilter += 1;
    });
    return visibleHotelNumberInFilter < 1;
  }).prop('disabled', true).trigger('formikation.update');

  FilterSidebar.updateFiltersCounter();
}


function getHotelsThatMatchCategory($inputs) {
  return $inputs.toArray().reduce(function (acc, element) {
    let hotelsByCountry = $(element).data('');


    if (hotelsByCountry !== undefined) {
      Object.keys(hotelsByCountry).forEach(function (country) {
        acc[country] = (acc[country] || []).concat(hotelsByCountry[country]);
      });
      return acc;
    }
  }, {});
}


function intersectArrays() {
  return Array.prototype.slice.call(arguments).reduce(function (acc, array) {
    return intersectTwoArrays(acc, array);
  });
}

function intersectTwoArrays(a, b) {
  if (!b) return a;
  return $.grep(a, function (i) {
    return $.inArray(i, b) > -1;
  });
}


function countHotels($element) {
  let $checked = $('.checked [data-filter]');
  let $checkedCategories = $("[data-filter='categories']:checked");
  let $checkedSpecialties = $("[data-filter='specialties']:checked");
  let $checkedAmenities = $("[data-filter='amenities']:checked");
  let $checkedFavourites = $("[data-filter='favourite']:checked");

  if ($checked.length != 0) {
    if ($checkedCategories.length == 0) {$checkedCategories = $("[data-filter='categories']");}
    if ($checkedSpecialties.length == 0) {$checkedSpecialties = $("[data-filter='specialties']");}
    if ($checkedAmenities.length == 0) {$checkedAmenities = $("[data-filter='amenities']");}
    if ($checkedFavourites.length == 0) {$checkedFavourites = $("[data-filter='favourite']");}
  }

  let hotelsAvailableByCategory = getHotelsThatMatchCategory($checkedCategories);
  let hotelsAvailableBySpecialty = getHotelsThatMatchCategory($checkedSpecialties);
  let hotelsAvailableByAmenity = getHotelsThatMatchCategory($checkedAmenities);
  let hotelsAvailableByFavourite = getHotelsThatMatchCategory($checkedFavourites);

  let allCountries = Object.keys($.extend({}, hotelsAvailableByCategory, hotelsAvailableBySpecialty, hotelsAvailableByAmenity, hotelsAvailableByFavourite));

  let result = allCountries.reduce(function (acc, country) {
    let hotelsToIntersect = [];
    if ($checkedCategories.length) hotelsToIntersect.push(hotelsAvailableByCategory[country] || {});
    if ($checkedSpecialties.length) hotelsToIntersect.push(hotelsAvailableBySpecialty[country] || {});
    if ($checkedAmenities.length) hotelsToIntersect.push(hotelsAvailableByAmenity[country] || {});
    if ($checkedFavourites.length && hotelsAvailableByFavourite) hotelsToIntersect.push(hotelsAvailableByFavourite[country] || {});

    let hotels = intersectArrays.apply(this, hotelsToIntersect);

    let newResult = (acc[country] || []).concat(hotels).unique();
    if (newResult.length > 0) acc[country] = newResult;
    return acc;
  }, {});

  if (($checked.length > 0) &&
          (($checkedCategories.length != $("[data-filter='categories']").length) ||
          ($checkedSpecialties.length != $("[data-filter='specialties']").length) ||
          ($checkedAmenities.length != $("[data-filter='amenities']").length) ||
          ($checkedFavourites.length != $("[data-filter='favourite']").length))) {
    Object.keys(result).forEach(function (country) {
      $('.js-dest-card[data-id=' + country + ']').find('.hotel-count').data('count', result[country].length);
      if ($('.js-dest-card[data-id=' + country + ']').length > 0) {
        combineText($('.js-dest-card[data-id=' + country + ']').find('.hotel-count'), result[country].length);
      }
    });
  } else {
    $.each($('.js-dest-card'), function (e, f) {
      $(f).find('.hotel-count').data('count', $(f).find('.hotel-count').data('all'));
      if ($(f).length > 0) {
        combineText($(f).find('.hotel-count'), $(f).find('.hotel-count').data('all'));
      }
    });
  }

  return result;
}

function updateFiltersCounter() {
  // Obtiene las ids de los hoteles visibles
  let visibleHotels = getVisibleHotels();
  if(currentHotels.length){
    visibleHotels = currentHotels;
  }

  let hotelsCount = 0;
  let favouriteHotelCodes = readCookie('favourite_hotel_codes');

  // Recorre todos los filtros para actualizar su contador de hoteles
  $('[data-filter]').each(function (index, elem) {
    hotelsCount = 0;

    let $el = $(elem);
    let $counterLabel = $el.closest('label').find('.hotel-count');
    let hotelsByZone = $el.data('hotels-by-zone') || $el.data('crs-offers-by-zone');

    // Sumatorio de los hoteles visibles
    for (let key in hotelsByZone) {
      hotelsByZone[key].map(function (hotelId) {
        if (visibleHotels.indexOf(hotelId) !== -1) hotelsCount += 1;
      });
    }
    if (($el.attr('id') === 'favourite' && favouriteHotelCodes !== null && favouriteHotelCodes !== '')) {
      // Actualización del contador de favoritos en el filtro
      //countFavouriteHotels($el, favouriteHotelCodes);
    } else {
      // Actualización del contador
      if (!$el.hasClass('init')) combineText($counterLabel, hotelsCount, visibleHotels.length);
      if ($el.data('filter') === 'destinations' && !$el.hasClass('init')) $el.addClass('init');
    }

    if(hotelsCount == 0){
      elem.parentElement.classList.add('disabled')
    }
  });
}

function combineText($span, count, total) {
  let trans_one = $span.data('one');
  let trans_other = $span.data('other');

  if ($span && (count || count === 0)) {
    if ($span && count > 1 && trans_other) {
      $span.html(trans_other.replace('{{count}}', count));
    } else if (trans_one) {
      $span.html(trans_one.replace('{{count}}', count));
    }
  }
}

// Get visible Hotels
function getVisibleHotels() {
  let visibleHotels = [];

  if ($('.wrapper.hotels-info-with-filtering').length > 0) {
    $('.wrapper.hotels-info-with-filtering').data('hotels-filterable-info').map(function(hotel, index) {
      hotel['hotels'].map(function (hotelId, i) {

        let obj = {};
        let data = hotel["filterable"][0];

        _forEach(data, function (value, key) {
          obj[key] = _difference(filters[key], data[key]).length === 0;
        });

        if (!_includes(_values(obj), false)) visibleHotels.push(hotelId);
      });
    });
  }

  // Get commons hotels from filters
  let filterHotels = [];
  $('.js-filters-sidebar').find('[data-filter="date"]:checked').map(function () {
    if($(this).data('hotels-by-zone') === undefined)
      return
    if (filterHotels.length > 0) {
      filterHotels = intersectTwoArrays(filterHotels, Object.values($(this).data('hotels-by-zone')).flat());
    } else {
      if (!AUTO_OFFER_LIST_PRESENT)
        filterHotels = filterHotels.concat(Object.values($(this).data('hotels-by-zone')).flat());
    }
  });



  //Get selected continent and check only hotels visible on it
  //If there is only one filter, concatenate hotels, and if not, get intersection of the filters
  let selectedContinent = $('[data-root-destination].active').data('root-destination');
  if ($('.filtering-container .' + selectedContinent + ' .js-dest-card.dest-card:not(.hidden)').length > 0) {
    $('.filtering-container .' + selectedContinent + ' .js-dest-card.dest-card:not(.hidden)').map(function (index) {
      let hotelIds = $(this).data('hotels');
      if (filterHotels.length) {
        visibleHotels = visibleHotels.concat(_filter(hotelIds, function (hotel) {return _includes(filterHotels, hotel);}));
      } else {
        visibleHotels = visibleHotels.concat(hotelIds);
      }
    });
  }

  return visibleHotels;
}

// Return true if visible in list or map
function isVisible(el) {
  let $el = $(el);
  let second_check = $el.closest('.root-destination').length ? (!$el.hasClass('hidden') && $el.closest('.root-destination').hasClass('current')) : !$el.hasClass('hidden');

  return $el.is(':visible') || second_check;
}



// Initialize search criteria with empty values or get the current one from LocalStorage key
function initializeOrFindSearch(search) {
  if (search === null) {
    search = {};
    search['destinations'] = [];
    search['categories'] = [];
    search['specialties'] = [];
    search['amenities'] = [];
    search['favourite'] = [];
  } else {
    search = JSON.parse(search);
  }
  return search;
}


// Check selected options in next page visualization
function checkOptions() {
  let search = getLocalStorage();

  $.each(search, function (key, filters_values) {
    if (filters_values.length > 0) {
      $('.filters-block.' + key + ' p').addClass('active').closest();
      $('.filters-block.' + key + ' div').removeClass('hidden');
    }
    $.each(filters_values, function (index, value) {
      $("[data-filter='" + key + "'][value='" + value + "']").prop('checked', true).change();
    });
  });
}

function checkFiltersBottomActionsVisibility() {
  let $filters_sidebar = $('.js-filters-sidebar');
  let $bottomActions = $filters_sidebar.find('.bottom-actions');

  // isLikeMobile de momento solo está disponible en la página de destino
  if ((!currentDevice.isMobile || (!currentDevice.isLikeMobile && IB.currentPageType.get() == 'destination'))) {
    //$bottomActions.hide();
    return;
  }

  // Then it's mobile
  let checkedFilters = $filters_sidebar.find('[data-filter]:checked').length;

  if (checkedFilters) {
    $bottomActions.css('display', 'flex');
    $bottomActions.removeClass('bottom-actions--apply-only');
  } else {
    let $orderOptionList = $('.js-filters-panel').find('.js-order-option-list');
    let lastOrderFilter = $orderOptionList.attr('data-current-order');
    let selectedOrderFilter = $orderOptionList.find('input:checked').val();

    // Check if order filter has changed
    if (lastOrderFilter === selectedOrderFilter) {
      $bottomActions.hide();
    } else {
      $bottomActions.css('display', 'flex');
      $bottomActions.addClass('bottom-actions--apply-only');
    }
  }
}

/**
 * Toggle current clicked filter
 *
 * @param {*} filter_block Clicked filter block
 */
function toggleFilters(filter_block) {
  let title = filter_block.querySelector('.js-filter-title');
  let filters = filter_block.querySelector('.js-filter-block');
  //let filters_index = filter_block.querySelectorAll('.filters-index');

  title.classList.toggle('active');
  filter_block.classList.toggle('active');

  $(filters).slideToggle();
}

function filterDropDownToggle() {
  on({
    eventName: 'click',
    selector: '.js-filters-sidebar .js-filter .js-filter-title',
    handler() {
      toggleFilters(this.closest('.js-filter'));
    },
  })
}

function initFilterSidebar() {
  if (!$('[data-filterable-wrap]').length) return false;

  FilterSidebar.updateFiltersCounter();

  filterDropDownToggle();

  $(document).on('change', '[data-filter]', function () {
    let $this = $(this);
    FilterSidebar.apply($('[data-filterable-wrap]'), $(this));
    FilterSidebar.updateFiltersCounter();
    $('.card-banner').remove();

  }).on('change', '.js-pagination-mobile-order-by', function () {
    checkFiltersBottomActionsVisibility();
  })
    .on('click', '.clear-all', function (e) {
      e.preventDefault();
      $('.destination-index-tags').remove();
      FilterSidebar.clear($('[data-filterable-wrap]'));
    })
    .on('click', '.destination-index-tags .tag', function (event) {
      event.preventDefault();
      let $this = $(this);
      let id = $this.data('id');
      $('.js-filters-sidebar').find('#' + id).prop('checked', false)
        .change();
    })
    .on('click', '.js-filters-sidebar .select-all input', function (e) {
      let $this = $(this);

      if ($this.is(':checked')) {
        $this.closest('.filters').find('[data-filter]:not(:checked)').each(function (index, element) {
          $(element).prop('checked', true).change();
        });
        $this.addClass('selected');
      } else {
        $this.closest('.filters').find('[data-filter]').each(function (index, element) {
          $(element).prop('checked', false).change();
        });
        $this.removeClass('selected');
      }
    });

    initializeUtagEvents();
}

window.FilterSidebar = {
  init:                                initFilterSidebar,
  apply:                               applyFilters,
  empty:                               emptyLocalStorage,
  clear:                               clearFilters,
  contains:                            contains,
  countHotels:                         countHotels,
  getFilters:                          getLocalStorage,
  checkOptions:                        checkOptions,
  updateFiltersCounter:                updateFiltersCounter,
  checkFiltersBottomActionsVisibility: checkFiltersBottomActionsVisibility
};


FilterSidebar.init();
